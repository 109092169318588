import React from 'react'
import styled from 'styled-components'
import { BREAKPOINTS } from '../theme'

const StyledButton = styled.div`
  margin: 20px auto;
  background-color: #ff0074;
  border-radius: 20px;
  height: 30px;
  width: 180px;
  text-align: center;
  line-height: 30px;
  font-size: 13px;

  &:hover {
    background-color: #a73267;
  }
  &&.Play {
    background-color: #9951d0;
    &:hover {
      background-color: #5f3084;
    }
  }
  &&.Unavailable {
    background-color: #bdbdbd;
    color: white;
    width: 250px;
  }
  a {
    text-decoration: none;
    color: white;
  }
  @media (max-width: ${BREAKPOINTS.sm}) {
    margin: 10px auto;
    font-size: 12px;
  }
`

const Button = ({ url, onClick, title, play = false, disable = false }) => 
  <StyledButton className={disable ? "Unavailable" : play ? "Play" : undefined}>
    <a href={url} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      {title}
    </a>
  </StyledButton>


export default Button