import React from 'react'
import styled from 'styled-components'
import { BREAKPOINTS } from '../theme'
import Contact from '../components/Contact';

const StyledFooter = styled.div`
  background-color: #000000;
  position: relative;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-size: 13px;

  @media (max-width: ${BREAKPOINTS.lg}) {
    height: auto;
    padding: 10px 0;
    font-size: 10px;
  }
`

const Footer = () => 
  <StyledFooter>
      <Contact />
    © Julie Nginn 2023
  </StyledFooter>

export default Footer