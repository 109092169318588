import React from 'react'
import styled from 'styled-components';
import map from 'lodash/map';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { HOBBIES } from '../fixtures';
import Title from '../components/Title';

const StyledWrapper = styled.div`
  padding: 10px;
  background-color: #bdbdbd;
  color: #555555;
`;

const StyledCardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledCard = styled(Card)`
  && {
    width: 345px;
    margin: 10px;
  }
`;

const Hobbies = () => 
  <StyledWrapper id="hobbies">
    <Title title="Mes passions" />
    <StyledCardsWrapper>
      {map(HOBBIES, hobby => (
        <StyledCard key={hobby.title}>
          <CardActionArea>
            <CardMedia
              component="img"
              alt={hobby.title}
              height="200"
              image={hobby.image}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {hobby.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {hobby.description}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href={hobby.url}>
              {hobby.button}
            </Button>
          </CardActions>
        </StyledCard>
      ))}
    </StyledCardsWrapper>
  </StyledWrapper>

export default Hobbies