import React, { useState, useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import styled from 'styled-components';
import map from 'lodash/map';
import get from 'lodash/get'
import Grow from '@material-ui/core/Grow';
import { MENU_ITEMS } from '../fixtures';
import { getPath } from '../routes'
import { BREAKPOINTS } from '../theme'

const TIME_OFFSET = 1000;

const StyledMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  a {
    text-decoration: none;
  }

  @media (max-width: ${BREAKPOINTS.md}) {
    width: 70%;
    justify-content: space-between;
  }

  @media (max-width: ${BREAKPOINTS.md}) {
    width: 100%;
    justify-content: center;
  }
`;

const StyledMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;

  @media (max-width: ${BREAKPOINTS.sm}) {
    margin: 10px;
  }
`;

const StyledMenuItemImage = styled.img`
  height: 180px;
  padding: 10px;
  transform-origin: '0 0 0';
  cursor: pointer;

  &:hover {
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  @media (max-width: ${BREAKPOINTS.md}) {
    height: 150px;
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    background-color: #ffffff;
    height: 100px;
    border-radius: 20px;
    
    &:hover {
      border-radius: 20px;
      box-shadow: none;
    }
  }

  @media (max-width: ${BREAKPOINTS.sm}) {
    height: 80px;
    padding: 5px;
  }
`;

const StyledLabel = styled.div`
  margin: 10px 0;
  padding: 6px 10px;
  background-color: #ffffff;
  border-radius: 10px;
  color: #383838;
  font-size: 10px;

    @media (max-width: ${BREAKPOINTS.sm}) {
    padding: 6px;
    font-size: 8px;
  }
`;

const MenuItem = ({ item, visible, index }) => {
  return (
    <Grow
      in={visible}
      {...(visible ? { timeout: TIME_OFFSET + (index * 1000) } : {})}
    >
      <AnchorLink href={`#${getPath(get(item, 'path'))}`}>
        <StyledMenuItem>
          <StyledMenuItemImage src={require(`../images/pictos/${item.path}.png`)} alt={item.title} />
          <StyledLabel>{item.title}</StyledLabel>
        </StyledMenuItem>
      </AnchorLink>
    </Grow>
  );
};

const MenuGrow = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, TIME_OFFSET / 2)
  }, []);

  return (
    <StyledMenu>
      {map(MENU_ITEMS, (item, index) => (
        <MenuItem visible={visible} key={item.title} item={item} index={index} />
      ))}
    </StyledMenu>
  );
}

export default MenuGrow;
