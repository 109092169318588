import React from 'react';
import styled from 'styled-components';
import map from 'lodash/map';
import { BREAKPOINTS } from '../theme'
import { CONTACT } from '../fixtures';
import { LinkIcon } from './Icon';

const StyledContact = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.sm}) {
    margin: 10px 0;
  }
`;

const Contact = () => 
  <StyledContact>
    {map(CONTACT, item => (
      <LinkIcon key={item.title} {...item} />
    ))}
  </StyledContact>

export default Contact;
