import React, { Component } from 'react'
import styled from 'styled-components'
import Home from './blocks/Home'
import About from './blocks/About'
import Projects from './blocks/Projects'
import Hobbies from './blocks/Hobbies'
import Footer from './blocks/Footer'
import axios from 'axios'

const StyledApp = styled.div`
  height: 100vh;
  margin: 0;
`

class App extends Component {
  state = {
    repos: []
  }

  componentDidMount() {
    axios.get(`https://api.github.com/users/Madylune/repos`).then(resp => {
      this.setState({ repos: resp.data })
    })
  }

  render() {
    return (
      <StyledApp>
        <Home />
        <About />
        <Projects repos={this.state.repos} />
        <Hobbies />
        <Footer />
      </StyledApp>
    )
  }
}

export default App
