const BREAKPOINT_XS = 0
const BREAKPOINT_SM = 48
const BREAKPOINT_MD = 64
const BREAKPOINT_LG = 75

export const BREAKPOINTS = {
  xs: `${BREAKPOINT_XS}em`,
  sm: `${BREAKPOINT_SM}em`,
  md: `${BREAKPOINT_MD}em`,
  lg: `${BREAKPOINT_LG}em`
}