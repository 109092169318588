import React, { useCallback, useState }  from 'react'
import styled from 'styled-components'
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import { PROJECTS } from '../fixtures'
import { BREAKPOINTS } from '../theme'
import ProjectCard from '../components/ProjectCard'
import Title from '../components/Title';

const StyledWrapper = styled.div``

const StyledProjects = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: ${BREAKPOINTS.sm}) {
    flex-direction: column;
    align-items: center;
  }
`

const StyledSubtitle = styled.h2`
  text-align: center;
  color: #949494;

  @media (max-width: ${BREAKPOINTS.sm}) {
    font-size: 1em;
  }
`

const StyledHr = styled.hr`
  width: 60%;
`

const Projects = ({ repos }) => {
  const [isOpen, setOpen] = useState(false)
  const [selectedProject, setSelectedProject] = useState(undefined)
  const openModal = useCallback(
    project => {
      if (project.title) {
        setOpen(true)
        setSelectedProject(project)
      }
    }, []
  )
  const closeModal = () => {
    if (isOpen) {
      setOpen(false)
      setSelectedProject(undefined)
    }
  }

  return (
    <StyledWrapper id="projects"> 
      <Title title="Mes projets" />
      <StyledHr />
      <StyledSubtitle>Jeux</StyledSubtitle>
      <StyledProjects>
        {map(project => (
          <ProjectCard key={project.title} repos={repos} project={project} showModal={openModal} isOpen={selectedProject === project && isOpen} closeModal={closeModal}/>
        ), filter({ type: 'game' }, PROJECTS))}
      </StyledProjects>
      <StyledHr />
      <StyledSubtitle>Web</StyledSubtitle>
      <StyledProjects>
        {map(project => (
          <ProjectCard key={project.title} repos={repos} project={project} showModal={openModal} isOpen={selectedProject === project && isOpen} closeModal={closeModal}/>
        ), filter({ type: 'web' }, PROJECTS))}
      </StyledProjects>
    </StyledWrapper>
  )
}

export default Projects