export const getPath = (routeName, params = {}) => {
  switch (routeName) {
    case 'home':
      return '/'
    default:
      return routeName
  }
}

export const getCurrentLocation = (locationName) => window.location.pathname === locationName
