import React from 'react';
import styled from 'styled-components'
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import map from 'lodash/fp/map';
import { BREAKPOINTS } from '../theme'

const StyledTimeline = styled(Timeline)`
  && {
    padding: 0;
    margin-top: 0;
  }
`;

const StyledTimelineItem = styled(TimelineItem)`
  && {
    @media (max-width: ${BREAKPOINTS.sm}) {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 5px;
    }
  }
`;

const StyledPaper = styled(Paper)`
  && {
    padding: 5px 15px;
    width: 70%;

    @media (max-width: ${BREAKPOINTS.md}) {
      width: 90%;
    }
    @media (max-width: ${BREAKPOINTS.sm}) {
      padding: 5px;
      width: 100%;
    }
  }
`;

const StyledDate = styled(Typography)`
  && {
    font-family: 'Press Start 2P';
    font-size: 12px;

    @media (max-width: ${BREAKPOINTS.md}) {
      font-size: 10px;
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
      position: absolute;
      top: 47px;
      left: 70px;
    }
  }
`;

const StyledTitle = styled(Typography)`
  && {
    font-family: 'Press Start 2P';
    font-size: 14px;

    @media (max-width: ${BREAKPOINTS.md}) {
      font-size: 10px;
    }
  }
`;

const StyledDescription = styled(Typography)`
  && {
    font-family: 'Press Start 2P';
    font-size: 11px;
    margin: 5px 0 10px;

    @media (max-width: ${BREAKPOINTS.md}) {
      font-size: 8px;
    }
  }
`;

const StyledTimelineDot = styled(TimelineDot)`
  && {
    background-color: ${({ pictoColor }) => pictoColor};

    @media (max-width: ${BREAKPOINTS.sm}) {
      margin-left: 15px;
    }
  }
`;

const StyledTimelineOppositeContent = styled(TimelineOppositeContent)`
  && {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    @media (max-width: ${BREAKPOINTS.md}) {
      width: 30%;
    }

    @media (max-width: ${BREAKPOINTS.sm}) {
      padding: 0;
      margin-top: 20px;
      margin-right: 7px;
      font-size: 8px;
    }
  }
`;

const StyledLabels = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const StyledLabel = styled.span`
  margin: 3px;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: ${({ pictoColor }) => pictoColor};
  color: #ffffff;
  font-size: 9px;

  @media (max-width: ${BREAKPOINTS.md}) {
    font-size: 8px;
  }
`;

export default function TimelineComponent({ items, pictoColor, picto }) {
  return (
    <StyledTimeline>
      {map(time => (
        <StyledTimelineItem key={time.what}>
          <StyledTimelineOppositeContent alignRight>
            <StyledDate variant="body2">{time.when}</StyledDate>
          </StyledTimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineDot pictoColor={pictoColor}>{picto}</StyledTimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <StyledPaper elevation={3}>
              <StyledTitle variant="subtitle1" component="h1">{time.where}</StyledTitle>
              <StyledDescription>
                {time.what}
              </StyledDescription>
              {time.stack && (
                <StyledLabels>
                  {map(item => (
                    <StyledLabel pictoColor={pictoColor} key={item}>{item}</StyledLabel>
                  ), time.stack)}
                </StyledLabels>           
              )}
              {time.url && (
                <Button size="small" variant="outlined" color="primary" href={time.url}>
                  voir
                </Button>
              )}
            </StyledPaper>
          </TimelineContent>
        </StyledTimelineItem>
      ), items)}
    </StyledTimeline>
  );
}
