import React from 'react'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import { getLastUpdate, getContextColor } from '../utils'
import { BREAKPOINTS } from '../theme'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import take from 'lodash/fp/take'
import size from 'lodash/fp/size'
import ProjectModal from './ProjectModal'
import Ribbon from './Ribbon'

const StyledCard = styled(Card)`
  && {
    position: relative;
    margin: 20px;
    width: 350px;
    box-shadow: 0 5px 12px rgba(0,0,0,0.5);
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: scale(1.05);
    }
    @media (max-width: ${BREAKPOINTS.sm}) {
      margin: 20px 0;
      width: 90%;

      &:hover {
        transform: scale(1);
      }
    }
  }
`

const StyledCardHeader = styled(CardHeader)`
  && {
    font-size: 9px;
    font-family: 'Press Start 2P';
    padding: 25px;

    .MuiCardHeader-subheader {
      font-size: 13px;
    }
  }
`

const StyledCardMedia = styled(CardMedia)`
  && {
    height: 0;
    padding-top: 56.25%; //16:9
  }
`

const StyledDescription = styled(Typography)`
  && {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 9px;
    font-family: 'Press Start 2P';
  }
`

const StyledCardActions = styled(CardActions)`
  && {
    border-top: 1px solid #eaeaea; 
    font-size: 9px;
    justify-content: center;
  }
`

const StyledTechno = styled.span`
  padding: 5px;
  border-radius: 20px;
  background-color: ${props => getContextColor(props.context)};
  color: #ffffff;
`

const ProjectCard = ({ repos, project, showModal, isOpen, closeModal }) => {
  const onProjectClick = () => {
    showModal(project)
  }
  const sliceOfTechno = take(3, project.techno) 
  return (
    <>
      <StyledCard onClick={onProjectClick}>
        <Ribbon context={project.context} />
        <StyledCardHeader
          title={get('title', project)}
          subheader={get('githubID', project) ? `Mis à jour ${getLastUpdate(repos, get('githubID', project))}` : `Publié en ${get('published', project)}`}
        />
        <StyledCardMedia
          image={require(`../images/projects/${project.picture}`)}
          title={get('title', project)}
        />
        <CardContent>
          <StyledDescription variant="body2" color="textSecondary" component="p">
            <span dangerouslySetInnerHTML={{ __html: get('description', project) }} />
          </StyledDescription>
        </CardContent>
        <StyledCardActions>
          {map(techno => 
            <StyledTechno key={techno} context={project.context}>{techno}</StyledTechno>
          , sliceOfTechno)}
          {size(project.techno) > size(sliceOfTechno) && <StyledTechno context={project.context}>...</StyledTechno>}
        </StyledCardActions>
      </StyledCard>
      {isOpen && (
        <ProjectModal isOpen={isOpen} project={project} onClose={closeModal} repos={repos} />
      )} 
    </>
  )
}

export default ProjectCard